.nav, .navbar-nav {
  .nav-item {
    .nav-link {
      display: flex;
      align-items: center;
      .image {
        width: 32px;
        margin-right: 0.5rem;
      }
    }
  }
}

.navbar {
  .navbar-nav {
    align-items: center;
  }
}