$btn-white-space: nowrap;
$primary: #012168;
$border-radius: 0.25rem;
$font-family: var(--bs-body-font-family);
$theme-colors: (
  
	'accent': #e02f32,
	'primary': #012168,
	'primary-dark': #000923,
	'primary-light': #4179ff,
	'primary-text': #212121,
	'secondary-text': #757575,
	'text-on-primary': #ffffff,
	'text-on-accent': #ffffff,
	'danger': #dc3545,
	'info': #17a2b8,
	'success': #28a745,
	'warning': #fce359,
	'dark': #343a40,
	'light': #f8f9fa,
	'blue': #007bff,
	'cyan': #17a2b8,
	'gray': #6c757d,
	'green': #28a745,
	'indigo': #6610f2,
	'orange': #fd7e14,
	'pink': #e83e8c,
	'purple': #6f42c1,
	'red': #dc3545,
	'teal': #20c997,
	'white': #ffffff,
	'yellow': #ffc107,
	'primary-dark-trans': #0d4675c4,
	'black-trans': #000000bd,
	'black': #000000,
	'lighten': #ffffffe6,
	'darken': #000000cc,
	'gray_b': #dedede,
	'footer_bg': #02324C,
	'footer_font': #ffffff,
	'lighten_med': #ffffffbf,
	'form_background': #F0F9FD,
	'form_inputs': #DDEDF9,
) !default;
