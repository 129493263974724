.video {
  &.cover {
    object-fit: cover;
  }

  &.contain {
    object-fit: contain;
  }

  &.fill {
    object-fit: fill;
  }
}
