.w-auto {
	width: auto !important;
}

.w-fill {
	width: 0;
	flex-grow: 1 !important;
}

.w-100 {
	width: 100% !important;
}

.max-w-1200px {
	max-width: 1200px !important;
}

.w-72px {
	width: 72px !important;
}

.w-32px {
	width: 32px !important;
}

.w-48px {
	width: 48px !important;
}

.min-w-320px {
	min-width: 320px !important;
}

.w-90 {
	width: 90% !important;
}

.min-w-300px {
	min-width: 300px !important;
}

.w-150px {
	width: 150px !important;
}

.max-w-500px {
	max-width: 500px !important;
}

.max-w-400px {
	max-width: 400px !important;
}

.w-80px {
	width: 80px !important;
}

.max-w-250px {
	max-width: 250px !important;
}

.min-w-150px {
	min-width: 150px !important;
}

.w-300px {
	width: 300px !important;
}

.w-50 {
	width: 50% !important;
}

.w-45 {
	width: 45% !important;
}

.min-w-350px {
	min-width: 350px !important;
}

.w-16px {
	width: 16px !important;
}

.max-w-100 {
	max-width: 100% !important;
}

.max-w-800px {
	max-width: 800px !important;
}

.w-80 {
	width: 80% !important;
}

.w-88 {
	width: 88% !important;
}

.w-24px {
	width: 24px !important;
}

.min-w-330px {
	min-width: 330px !important;
}

.max-w-1000px {
	max-width: 1000px !important;
}

.min-w-240px {
	min-width: 240px !important;
}

.min-w-400px {
	min-width: 400px !important;
}

.max-w-600px {
	max-width: 600px !important;
}

.max-w-900px {
	max-width: 900px !important;
}

.w-92 {
	width: 92% !important;
}

.w-196px {
	width: 196px !important;
}

.max-w-90 {
	max-width: 90% !important;
}

.max-w-550px {
	max-width: 550px !important;
}

.max-w-30 {
	max-width: 30% !important;
}

.min-w-200px {
	min-width: 200px !important;
}

.max-w-300px {
	max-width: 300px !important;
}

.w-128px {
	width: 128px !important;
}

.w-130px {
	width: 130px !important;
}

.w-20px {
	width: 20px !important;
}

.min-w-340px {
	min-width: 340px !important;
}

.min-w-380px {
	min-width: 380px !important;
}

.min-w-250px {
	min-width: 250px !important;
}

.w-320px {
	width: 320px !important;
}

.min-w-500px {
	min-width: 500px !important;
}

.w-60px {
	width: 60px !important;
}

.w-168px {
	width: 168px !important;
}

.w-95 {
	width: 95% !important;
}

.min-w-100px {
	min-width: 100px !important;
}

.max-w-50 {
	max-width: 50% !important;
}

.w-200px {
	width: 200px !important;
}

.w-600px {
	width: 600px !important;
}

.max-w-80 {
	max-width: 80% !important;
}

.max-w-450px {
	max-width: 450px !important;
}

.min-w-80px {
	min-width: 80px !important;
}

