.mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.my-auto {
	margin-top: auto;
	margin-bottom: auto;
}

.ms-16px {
	margin-left: 16px;
}

.me-12px {
	margin-right: 12px;
}

.mt-32px {
	margin-top: 32px;
}

.me-8px {
	margin-right: 8px;
}

.mt-48px {
	margin-top: 48px;
}

.mt-24px {
	margin-top: 24px;
}

.mb-16px {
	margin-bottom: 16px;
}

.me-32px {
	margin-right: 32px;
}

.me-16px {
	margin-right: 16px;
}

.m-6px {
	margin: 6px;
}

.m-8px {
	margin: 8px;
}

.mt-8px {
	margin-top: 8px;
}

.mt-16px {
	margin-top: 16px;
}

.ms-12px {
	margin-left: 12px;
}

.ms-4px {
	margin-left: 4px;
}

.mt-12px {
	margin-top: 12px;
}

.mb-8px {
	margin-bottom: 8px;
}

.my-52px {
	margin-top: 52px;
	margin-bottom: 52px;
}

.mb-48px {
	margin-bottom: 48px;
}

.my-4px {
	margin-top: 4px;
	margin-bottom: 4px;
}

.mx-24px {
	margin-left: 24px;
	margin-right: 24px;
}

.ms-24px {
	margin-left: 24px;
}

.mt-64px {
	margin-top: 64px;
}

.mt--128px {
	margin-top: -128px;
}

.mb-128px {
	margin-bottom: 128px;
}

.mb-64px {
	margin-bottom: 64px;
}

.mb--96px {
	margin-bottom: -96px;
}

.mt-4px {
	margin-top: 4px;
}

.mb-24px {
	margin-bottom: 24px;
}

.ms-8px {
	margin-left: 8px;
}

.me-6px {
	margin-right: 6px;
}

.mb-6px {
	margin-bottom: 6px;
}

.mb-26px {
	margin-bottom: 26px;
}

.me-24px {
	margin-right: 24px;
}

.me-26px {
	margin-right: 26px;
}

.ms-6px {
	margin-left: 6px;
}

.me-4px {
	margin-right: 4px;
}

.mt-52px {
	margin-top: 52px;
}

.m-24px {
	margin: 24px;
}

.mt-188px {
	margin-top: 188px;
}

