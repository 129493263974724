.f-20px {
  font-size: 20px;
}

.f-28px {
  font-size: 28px;
}

.f-30px {
  font-size: 30px;
}

.f-40px {
  font-size: 40px;
}

.f-small {
  font-size: small;
}

.f-bold {
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase;
}


@font-face {
	font-family: renner;
	src: url('../../assets/fonts/Renner.ttf');
}

.ff-renner {
font-family: renner;
}

@font-face {
	font-family: hurmegeometricsans3black;
	src: url('../../assets/fonts/HurmeGeometricSans3Black.otf');
}

.ff-hurmegeometricsans3black {
font-family: hurmegeometricsans3black;
}

@font-face {
	font-family: black;
	src: url('../../assets/fonts/Montserrat-Black.ttf');
}

.ff-black {
font-family: black;
}

@font-face {
	font-family: bold;
	src: url('../../assets/fonts/Montserrat-Bold.ttf');
}

.ff-bold {
font-family: bold;
}

@font-face {
	font-family: medium;
	src: url('../../assets/fonts/Montserrat-Medium.ttf');
}

.ff-medium {
font-family: medium;
}

@font-face {
	font-family: regular;
	src: url('../../assets/fonts/Montserrat-Regular.ttf');
}

.ff-regular {
font-family: regular;
}

@font-face {
	font-family: montserratblack;
	src: url('../../assets/fonts/Montserrat-Black.ttf');
}

.ff-montserratblack {
font-family: montserratblack;
}

@font-face {
	font-family: montserratbold;
	src: url('../../assets/fonts/Montserrat-Bold.ttf');
}

.ff-montserratbold {
font-family: montserratbold;
}

@font-face {
	font-family: montserratmedium;
	src: url('../../assets/fonts/Montserrat-Medium.ttf');
}

.ff-montserratmedium {
font-family: montserratmedium;
}

@font-face {
	font-family: montserratregular;
	src: url('../../assets/fonts/Montserrat-Regular.ttf');
}

.ff-montserratregular {
font-family: montserratregular;
}

@font-face {
	font-family: slogan;
	src: url('../../assets/fonts/Palanquin-SemiBold.ttf');
}

.ff-slogan {
font-family: slogan;
}

@font-face {
	font-family: palanquinsemibold;
	src: url('../../assets/fonts/Palanquin-SemiBold.ttf');
}

.ff-palanquinsemibold {
font-family: palanquinsemibold;
}

@font-face {
	font-family: semibolditalic;
	src: url('../../assets/fonts/Montserrat-SemiBoldItalic.ttf');
}

.ff-semibolditalic {
font-family: semibolditalic;
}

@font-face {
	font-family: montserratsemibolditalic;
	src: url('../../assets/fonts/Montserrat-SemiBoldItalic.ttf');
}

.ff-montserratsemibolditalic {
font-family: montserratsemibolditalic;
}



.f-16px {
	font-size: 16px;
}

.f-12px {
	font-size: 12px;
}

.f-32px {
	font-size: 32px;
}

.f-26px {
	font-size: 26px;
}

.f-24px {
	font-size: 24px;
}


