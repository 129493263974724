.p-10px {
	padding: 10px;
}

.p-20px {
	padding: 20px;
}

.p-1rem {
	padding: 10px;
}

.px-1_5rem {
	padding-left: 1.5rem;
	padding-right:1.5rem;
}

.py-1rem {
	padding-top: 1rem;
	padding-bottom:1rem;
}

.p-24px {
	padding: 24px;
}

.px-36px {
	padding-left: 36px;
	padding-right: 36px;
}

.py-12px {
	padding-top: 12px;
	padding-bottom: 12px;
}

.p-12px {
	padding: 12px;
}

.pb-24px {
	padding-bottom: 24px;
}

.p-4px {
	padding: 4px;
}

.p-32px {
	padding: 32px;
}

.px-24px {
	padding-left: 24px;
	padding-right: 24px;
}

.pt-24px {
	padding-top: 24px;
}

.px-12px {
	padding-left: 12px;
	padding-right: 12px;
}

.py-6px {
	padding-top: 6px;
	padding-bottom: 6px;
}

.py-48px {
	padding-top: 48px;
	padding-bottom: 48px;
}

.pe-12px {
	padding-right: 12px;
}

.p-16px {
	padding: 16px;
}

.py-4px {
	padding-top: 4px;
	padding-bottom: 4px;
}

.pt-8px {
	padding-top: 8px;
}

.ps-16px {
	padding-left: 16px;
}

.p-8px {
	padding: 8px;
}

.pe-8px {
	padding-right: 8px;
}

.ps-12px {
	padding-left: 12px;
}

.px-32px {
	padding-left: 32px;
	padding-right: 32px;
}

.py-24px {
	padding-top: 24px;
	padding-bottom: 24px;
}

.pt-96px {
	padding-top: 96px;
}

.pb-128px {
	padding-bottom: 128px;
}

.px-16px {
	padding-left: 16px;
	padding-right: 16px;
}

.py-8px {
	padding-top: 8px;
	padding-bottom: 8px;
}

.pb-16px {
	padding-bottom: 16px;
}

.p-64px {
	padding: 64px;
}

.p-48px {
	padding: 48px;
}

.pe-64px {
	padding-right: 64px;
}

.pt-40px {
	padding-top: 40px;
}

.pb-64px {
	padding-bottom: 64px;
}

.pe-32px {
	padding-right: 32px;
}

.pe-48px {
	padding-right: 48px;
}

.pe-24px {
	padding-right: 24px;
}

.p-25px {
	padding: 25px;
}

.ps-8px {
	padding-left: 8px;
}

.py-64px {
	padding-top: 64px;
	padding-bottom: 64px;
}

.px-8px {
	padding-left: 8px;
	padding-right: 8px;
}

.pb-8px {
	padding-bottom: 8px;
}

.pb-4px {
	padding-bottom: 4px;
}

.py-220px {
	padding-top: 220px;
	padding-bottom: 220px;
}

.pe-6px {
	padding-right: 6px;
}

.pb-32px {
	padding-bottom: 32px;
}

.p-3 {
	padding: 3%;
}

