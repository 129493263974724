.dropdown {
    border-radius: 0.25rem;
    &[data-arrow='false'] {
        .dropdown-toggle {
            &:after {
                display: none;
            }
        }
    }
    &[data-image='true'] {
        .dropdown-toggle {
            overflow: hidden;
            padding: 0;
            &:after {
                position: absolute;
                right: -16px;
            }
            img {
                max-width: 40px;
                max-height: 40px;
                object-fit: contain;
            }
        }
    }
    &:not([class*="w-auto"]) {
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .dropdown-toggle {
        align-items: center;
        border-radius: inherit;
        display: flex;
    }
    .dropdown-menu {
        .dropdown {
            width: 100%;
            .dropdown-toggle {
                background-color: transparent;
                border-radius: 0;
                box-shadow: none;
                border: none;
                width: 100%;
                padding: 0.25rem 1rem;
                text-align: left;
                .text {
                    flex-grow: 1;
                }
            }
            .dropdown-menu {
                border-left: none;
                border-right: none;
                border-radius: 0;
                position: relative !important;
                transform: none !important;
            }
        }
    }
}
  